import { Link } from "gatsby";
import React from "react";

const DivButton = ({ children, className }) => {
  return (
    <div
      className={`${
        className ? className : "bg-actionBlue hover:bg-opacity-50 text-white"
      } inline-block px-8 py-3 text-lg rounded-full text-center`}
    >
      {children}
    </div>
  );
};
const ButtonAction = ({ href, to, children, className, newWindow }) => {
  return (
    <>
      {href !== undefined ? (
        <a
          className="button-action"
          href={href}
          target={newWindow ? "_blank" : ""}
          rel="noreferrer"
        >
          <DivButton className={className}>{children}</DivButton>
        </a>
      ) : (
        <Link className="button-action" to={to}>
          <DivButton className={className}>{children}</DivButton>
        </Link>
      )}
    </>
  );
};

export default ButtonAction;
